import OneSignal from 'react-onesignal';
import { Store } from './services/store';

export default async function runOneSignal() {
    await OneSignal.init({
         appId: '8db806c9-4fb2-4d84-9daa-39384f8154c2', // one signal Prod
        // appId: '6499f120-48b8-4310-8d6d-21d36c180e79', // OneSignal WebTest
        notifyButton: {
          enable: true,
        },
        allowLocalhostAsSecureOrigin: true
    });
    window.OneSignal = window.OneSignal || [];
}

